<template>
	<div class="tcontainer">
		<Toast />
		<div class="clearfix ttitle">
			<div class="pull-right" style="margin: 5px;">
				<router-link to="/admin/xray-reporting">X-Ray Reporting</router-link>
			</div>
			<div class="title">
				X-Ray Overview ({{totalReported}}) &nbsp; 
				(Uptime Since: {{ format(data?.uptimeSince, "DD/MM/yy hh:mm a") }})
			</div>

			
		</div>
		<div>
			<div style="height: 30px;"></div>
			<div class="container-fluid">
				<div class="row">
					<div class="col-4 card">
						<h5 style="padding-top: 8px;">System Pool</h5>
						<div>
							<ol>
								<li v-for="v in data?.openReports" :key="v">
									<span :class="{'text-danger': v.urgent}">#{{ v.id }}</span> <span class="badge badge-info">{{ v.category }}</span>
								</li>
							</ol>
						</div>
					</div>
					<div class="col-4 card">
						<h5 style="padding-top: 8px;">Active Reports</h5>
						<div>
							<ol>
								<li v-for="v in data?.reports" :key="v">
									<div>ID: {{ v.id }}</div>
									<div>State: {{ v.state }}</div>
									<div>Doctor: #{{ v.doctorId }} {{ v.doctor }}</div>
								</li>
							</ol>
						</div>
					</div>
					<div class="col-4 card">
						<h5 style="padding-top: 8px;">Delayed Reports</h5>
						<div>
							<ol class="text-danger">
								<li v-for="v in data?.delayedReportes" :key="v">
									<div>
										#{{ v.id }} Delaly {{v.delay}}

										<div v-if="v.assign_type == 'all'" class="badge badge-success">SP</div>
										<div v-if="v.assign_type == 'specific'" class="badge badge-danger">DP</div>

									</div>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			<div style="height: 10px;"></div>

			<div class="card" style="margin: 20px 0; padding: 20px;" v-for="doc in doctors" :key="doc">
				<h4 style="margin-bottom: 20px;">
					<span v-if="doc.online" class="text-success fa fa-circle"></span>
					#{{ doc.id }} {{doc.name}} ({{ doc.totalReported }})
				</h4>
				<div class="container-fluid">
					<div class="row">
						<div class="col-2 card">
							<b style="padding: 8px 0;">Direct Pool</b>
							<div>
								<ol>
									<li v-for="v in doc.reports" :key="v">
										#{{ v }}
									</li>
								</ol>
							</div>
						</div>
						<div class="col-2 card">
							<b style="padding: 8px 0;">Direct Urgent Pool</b>
							<div>
								<ol>
									<li v-for="v in doc.reportsUrgent" :key="v">
										#{{ v }}
									</li>
								</ol>
							</div>
						</div>
						<div class="col-2 card">
							<b style="padding: 8px 0;">Reviews Pool</b>
							<div>
								<ol>
									<li v-for="v in doc.reviews" :key="v">
										#{{ v }}
									</li>
								</ol>
							</div>
						</div>
						<div class="col-2 card">
							<b style="padding: 8px 0;">Rejected Reports</b>
							<div>
								<ol>
									<li v-for="v in doc.rejectedReports" :key="v">
										#{{ v }}
									</li>
								</ol>
							</div>
						</div>
						<div class="col-4 card">
							<b style="padding: 8px 0;">Sockets</b>
							<div>
								<ol>
									<li v-for="v in doc.sockets" :key="v">
										<div>
											<b>State: </b> {{ v.state }}
										</div>
										<div>
											<b>Report Id: </b> {{ v.reportId }}
										</div>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		
	</div>
</template>
<script>
import Toast from 'primevue/toast';
export default {
	components: {
		Toast,
	},
	data() {
		return {
			data: null,
			isSuper: false,

			totalReported: 0,

			doctors: [],

		}
	},
	mounted() {
		this.loadLazyData();
	},
	methods: {
		enter() {
			let user = JSON.parse(window.localStorage.admin_access_token);
			this.isSuper = user.is_super == 'y';
		},
		async loadLazyData() {
			let d = await this.get("../rad");
			console.log(d);
			this.data = d;

			for(let i in d.doctors){
				if(!d.doctors[i].active) continue;
				this.doctors.push(d.doctors[i])

				this.totalReported += d.doctors[i].totalReported;

			}

			for(let r of d.reports){
				if(r.doctorId){
					let doc = this.doctors.find(d=>d.id == r.doctorId)

					if(doc){
						r.doctor = doc.name;
					}
				}
			}


			this.doctors = this.doctors.sort(d=>d.online?-1:1);

		},
	},
}
</script>